import React from "react";
import PageStateKit from "./PageStateKit";

const PageStateProvider = ({ Owl }) => {
    console.log("PageStateProvider RD");
    [Owl.pageState, Owl.setPageState] = React.useState(["none"]);
    const Body = React.useMemo(() => {
        const PageState = PageStateKit[Owl.pageState[0]];
        return <PageState owl={Owl} />;
    }, [Owl.pageState]);
    return Body;
};

export default PageStateProvider;
