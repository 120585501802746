import ItemsLoading from "./ItemsLoading";
import Loading from "./Loading";
import Error from "./Error";
import Progressing from "./Progressing";
import Searching from "./Searching";

const PageStateKit = {
    loading: Loading,
    itemsLoading: ItemsLoading,
    progressing: Progressing,
    error: Error,
    searching: Searching,
    none: () => <></>,
};

export default PageStateKit;
