import React from "react";

const LogScroller = ({ fetchData, canLoadMore, children, loading }) => {
    const itemsScroll = ({ target }) => {
        if (canLoadMore && target.scrollHeight - target.scrollTop < target.clientHeight + 100) {
            canLoadMore = false;
            fetchData(false);
        }
    };
    return (
        <div onScroll={itemsScroll} className={`overflow-auto z-20 backdrop-blur-sm- px-1 scroller h-screen fixed left-0 right-0 lg:pr-[250px]`}>
            {children}
        </div>
    );
};

export default LogScroller;
