import React from "react";
import InputText from "../Components/InputText";
import { getAxiosInstance } from "../Utils/Globals";

const TestEndpoints = ({ setOwl, owl }) => {
    const [endpoint, setEndpoint] = React.useState(owl?.endpoint || "");
    const [selected, setSelected] = React.useState(owl?.selected || 0);

    const APIs = React.useMemo(() => {
        try {
            let _apis = JSON.parse(localStorage.getItem("APIs"));
            console.log("APIs", _apis);
            if (_apis) {
                Object.values(_apis).map((val, i) => {
                    val.title = val.baseURL.split("/")[2].split(".")[0];
                });
                return Object.values(_apis);
            } else return [];
        } catch (e) {
            console.log(e);
            return [];
        }
    }, []);

    const onSubmit = (e) => {
        e.preventDefault();
        submit();
        return false;
    };

    const submit = () => {
        let val = APIs[selected];
        console.log(val);
        // val.baseURL = `${val.baseURL}/${endpoint}`;
        let api = getAxiosInstance(val);
        console.log({ api, endpoint });
        setOwl(false);
        setTimeout(() => {
            setOwl({ api, endpoint, selected });
        }, 100);
    };

    return (
        <form onSubmit={onSubmit} className="col p-3 gap-3" style={{ marginInline: owl ? -12 : 0 }}>
            <div className="wrap gap-4">
                {APIs.map((val, i) => {
                    console.log(val);
                    return (
                        <p
                            className={`shadow-1 rounded-xl p-2 text-gray-900 ${selected === i ? "bg-cyan-400 text-white" : "bg-white"}`}
                            onClick={() => {
                                setSelected(i);
                            }}>
                            {val.title}
                        </p>
                    );
                })}
            </div>
            {setOwl && (
                <>
                    <InputText placeholder={"endpoint"} value={endpoint} setValue={setEndpoint} />
                    <input type="submit" value="submit" className="hide" />
                </>
            )}
        </form>
    );
};

export default TestEndpoints;
