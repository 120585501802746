import { hasValue } from "../../Utils/Globals";
import UrlsGenerater from "../UrlGenertor";

const _testOwlMethods = {
    getOwl: async (Owl, { url, onResult, onError, api, queries }) => {
        Owl.url = url;
        Owl.onResult = onResult;
        Owl.onError = onError;
        Owl.api = api;
        Owl.canLoadMore = true;
        Owl.offset = "";
        Owl.items = "";
        Owl.queryParams = {};
        Owl.requestId = "";
        Owl.setQueryParams = false;
        Owl.defaultQueryParams = {};

        let defaultQP = {};
        if (queries) defaultQP = await setupQueryForFirstTime(queries, defaultQP);
        Owl.fetch = async (method = "get") => {
            Owl.setPageState(["itemsLoading"]);
            Owl.canLoadMore = false;
            let requestId = setRequestId();
            let query = Owl.query + `&offset=${Owl.offset}`;
            try {
                const { data } = await Owl.api[method](query);
                if (validRequest(requestId)) _OnResult(data);
                else console.error("Invalid request");
            } catch (error) {
                _OnError(error);
            }
        };
        Owl.search = async (method = "get") => {
            // let qeuryObj = getQuery(base.queries);
            // Owl.setItems([]);
            Owl.setPageState(["searching"]);
            Owl.offset = 0;
            Owl.limit = Owl.queryParams?.limit || 25;
            Owl.query = UrlsGenerater(Owl.queryParams, Owl.url);
            // Owl.queryParams = qeuryObj;
            Owl.canLoadMore = false;
            let requestId = setRequestId();
            try {
                const { data } = await Owl.api[method](Owl.query);
                if (validRequest(requestId)) _OnResult(data);
                else console.error("Invalid request");
            } catch (error) {
                _OnError(error);
            }
        };
        await firstRequest(Owl, defaultQP);

        //* Validation
        const setRequestId = () => {
            let requestId = Math.random();
            Owl.requestId = requestId;
            return requestId;
        };
        const validRequest = (requestId) => Owl.requestId === requestId;
        //*---
        const _OnResult = (data) => {
            if (Owl.onResult) data = Owl.onResult(data);
            let items = [];
            let _data = {};

            if (getType(data) === "Object") {
                Object.entries(data).forEach(([key, value]) => {
                    if (Array.isArray(value)) items = value;
                    else _data[key] = value;
                });
            } else {
                items = data.length > 0 ? data : null;
            }
            Owl.setItems(Owl.offset === 0 ? items : (owl) => [...owl, ...items]);
            Owl.set(_data);
            Owl.offset += items.length;
            Owl.canLoadMore = items.length == Owl.limit;
            Owl.setPageState(["none"]);
        };

        const _OnError = (error) => {
            console.log({ error });
            Owl.onError && Owl.onError(error);
            if (error.response && error.response.status) {
                let status = error.response.status;
                let main = {
                    code: error.code,
                    name: error.name,
                    message: error.message,
                    status: { [status]: error.response.data.title },
                    config_: { baseURL: error.config.baseURL },
                };
                let header = error.config.headers;
                error = { ...main, header, ...error };
                delete error.config;
                delete error.response;
            } else if (error.stack) {
                error = { message: error.message, stack: error.stack };
            }
            Owl.setPageState(["error", error]);
        };
        return Owl;
    },
};
const getType = (obj) => Object.prototype.toString.call(obj).slice(8, -1);

let count = 0;
const firstRequest = async (Owl, defaultQP) => {
    setTimeout(() => {
        if (Owl.setQueryParams !== false) {
            Owl.queryParams = defaultQP;
            Owl.setQueryParams(defaultQP);
            Owl.search();
        } else {
            if (count < 10) {
                count++;
                console.log({ count });
            }
        }
    }, 100);
};

const setupQueryForFirstTime = (queries, defaultQP) => {
    queries &&
        queries.length > 0 &&
        queries.map((group) => {
            handleQueryValues(group, defaultQP);
        });
    return defaultQP;
};
const handleQueryValues = (group, defaultQP) => {
    group?.children.map((query) => {
        if (query.storageKey) {
            let storageValue = localStorage.getItem(query.storageKey);
            if (storageValue) {
                if (query.type === "Boolean") storageValue = storageValue === "true";
                else query.value = storageValue;
            }
        }
        if (query.type === "Options") {
            query.className = query.options.find((o) => o.id == query.value)?.className || "";
        } else if (query.type === "ButtonsOptions") {
            let i = query.options.findIndex((o) => o.id == query.value);
            if (i !== -1) query.options[i].className = "owl-button-active";
        } else if (query.children) {
            setupQueryForFirstTime(query, defaultQP);
        }
        if (hasValue(query.value)) defaultQP[query.key] = { value: query.value, title: query.title || "_" };
    });
};
export default _testOwlMethods;
