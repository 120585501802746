import React from "react";
import CompanyCard from "../Components/CompanyCard";
import OwlConatiner from "../_testing/OwlConatiner";
import Companies from "../_testing/ViewsData/Companies";

const CompainesView = () => {
    return (
        <OwlConatiner //
            itemBuilder={CompanyCard}
            url={Companies.url}
            queries={Companies.queries}
            onResult={Companies.onResult}
            onError={Companies.onError}
        />
    );
};

export default CompainesView;
