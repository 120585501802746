import moment from "moment";

const _testFormateDate = (date) => {
    return {
        string: moment(date).locale("ar").fromNow(),
        time: new Date(date).toLocaleString().split(","),
    };
};

export default _testFormateDate;
