import React from "react";

const getClass = (prop) => "owl-buttons-options " + (prop.className || "");

const ButtonsOptions = ({ prop }) => {
    console.log("OptionsButtons RD");

    const _class = getClass(prop);
    const ref = React.useRef(null);

    const onClick = (e, value) => {
        prop.value = value;
        console.log("prop.value", prop.value);
        ref.current.childNodes.forEach((child) => {
            child.className = "owl-button";
        });
        e.currentTarget.className = "owl-button owl-button-active";
        if (prop.storageKey) localStorage.setItem(prop.storageKey, value);
        prop.onChange();
    };

    return (
        <div ref={ref} key={prop.key} defaultValue={prop.value} className={_class} onChange={(e) => {}}>
            {prop.options.map((item) => (
                <button onClick={(e) => onClick(e, item.id)} className={`owl-button ${item.className}`} value={item.id} key={item.id}>
                    {item.title}
                </button>
            ))}
        </div>
    );
};

export default ButtonsOptions;
