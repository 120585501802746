import React from "react";
import InputText from "../Components/InputText";
import Button from "../Components/Button";

const SetupView = () => {
    const [baseURL, setBaseUrl] = React.useState("");
    const [token, setToken] = React.useState("");
    const [appPackage, setAppPackage] = React.useState("");
    const [APIs, setAPIs] = React.useState(JSON.parse(localStorage.getItem("APIs")) || {});
    const [otherHeaderPropKey, setOtherHeaderPropKey] = React.useState("");
    const [otherHeaderPropValue, setOtherHeaderPropValue] = React.useState("");

    const onSubmit = () => {
        let APIs = JSON.parse(localStorage.getItem("APIs")) || {};
        let other = { otherHeaderPropKey: otherHeaderPropValue };
        APIs[baseURL] = { baseURL, token, appPackage, other };
        localStorage.setItem("APIs", JSON.stringify(APIs));
        setAPIs(JSON.parse(localStorage.getItem("APIs")) || {});
    };

    return (
        <>
            <div className="item-builder gap-3 m-10">
                <InputText placeholder={"base url"} value={baseURL} setValue={setBaseUrl} />
                <InputText placeholder={"token"} value={token} setValue={setToken} />
                <InputText placeholder={"app package"} value={appPackage} setValue={setAppPackage} />
                <div className="wrap gap-5">
                    <InputText placeholder={"other header key"} value={otherHeaderPropKey} setValue={setOtherHeaderPropKey} />
                    <InputText placeholder={"other header value"} value={otherHeaderPropValue} setValue={setOtherHeaderPropValue} />
                </div>
                <Button className="self-center" onClick={onSubmit} title={"submit"} />
            </div>
            <div className="wrap gap-1 p-2">
                {Object.values(APIs).map((val) => {
                    return (
                        <div className="row-center gap-3 px-2">
                            <span
                                className="text-white bg-red-400 shadow-1 rounded-full pointer"
                                style={{ padding: "2px 9px" }}
                                onClick={() => {
                                    let APIs = JSON.parse(localStorage.getItem("APIs"));
                                    delete APIs[val.baseURL];
                                    localStorage.setItem("APIs", JSON.stringify(APIs));
                                    setAPIs(JSON.parse(localStorage.getItem("APIs")) || {});
                                }}>
                                x
                            </span>
                            <p className={`shadow-1 rounded-xl p-2 text-gray-900 bg-white`}>{val.baseURL}</p>
                        </div>
                    );
                })}
            </div>
        </>
    );
};

export default SetupView;
