import React from "react";
import { Link } from "react-router-dom";

const MainHeader = () => {
    console.log("MainHeader RD");

    return (
        <div className="bg-white row p-1 gap-4 justify-center z-50 shadow-gray relative">
            <Link className="link bg-white px-3 p-1 font-bold mx-1 rounded shadow-1" to="/">
                Setup
            </Link>
            <Link className="link bg-white px-3 p-1 font-bold mx-1 rounded shadow-1" to="/test">
                TEST
            </Link>
            <Link className="link bg-white px-3 p-1 font-bold mx-1 rounded shadow-1" to="/base">
                Base
            </Link>
            {/* <Link className="link bg-white px-3 p-1 font-bold mx-1 rounded shadow-1" to="/companies">
                Companies
            </Link>
            <Link className="link bg-white px-3 p-1 font-bold mx-1 rounded shadow-1" to="/dongles">
                Dongles
            </Link>
            <Link className="link bg-white px-3 p-1 font-bold mx-1 rounded shadow-1" to="/errors">
                Errors
            </Link> */}
        </div>
    );
};

export default MainHeader;
