import React from "react";
import QueryCleaner from "./_testQueryCleaner";
import QueryGroupContainer from "./_testQueryGroupBuilder";

const QueryContainer = ({ Owl, children, onChange, className }) => {
    console.log("QueryContainer RD");
    return (
        <div className={className}>
            <QueryGroupContainer prop={{ children, onChange }} />
            <QueryCleaner Owl={Owl} />
        </div>
    );
};

export default QueryContainer;
