import React from "react";

const QueryCleaner = ({ Owl }) => {
    [Owl.queryParams, Owl.setQueryParams] = React.useState({});
    console.log("QueryCleaner RD");

    const toDelete = React.useMemo(() => {
        return Object.entries(Owl.queryParams).filter(([_, value]) => value.clear);
    }, [Owl.queryParams]);

    return toDelete.length > 0 ? (
        <div className="owl-query-cleaner">
            {toDelete.map(([_, _queryProp]) => (
                <button
                    key={_}
                    onClick={() => {
                        delete Owl.queryParams[_];
                        Owl.setQueryParams({ ...Owl.queryParams });
                        _queryProp.clear();
                        Owl.search();
                    }}
                    className="owl-button p-1 px-2">
                    <span>{`X- ${_queryProp.title} : ${_queryProp.value}`}</span>
                </button>
            ))}
        </div>
    ) : (
        <></>
    );
};

export default QueryCleaner;
