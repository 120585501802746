import axios from "axios";
const HubCoreRoot = `https://hubcore.morabaaapps.com/api/v1`;
const HubLogsRoot = "https://cosmic-boaconstrictor.morabaa.cloud/api/v1";
// const HubLogsRoot = "http://localhost:18699/api/v1";

const getAxiosInstance = (root) => {
    let instance = axios.create({
        baseURL: root,
        headers: {
            "Content-Type": "application/json",
            "App-Package": "com.morabaa.hub",
        },
    });
    instance.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem("token")}`;
    return instance;
};

// Api.interceptors.request.use(
//     (config) => {
//         const token = localStorage.getItem("accessToken");
//         const selectedBusinessId = localStorage.getItem("selectedBusinessId");
//         if (token) {
//             config.headers["Authorization"] = token;
//             config.headers["X-Business-Id"] = selectedBusinessId;
//         }
//         return config;
//     },
//     (error) => {
//         return Promise.reject(error);
//     }
// );

export const updateToken = (token) => {
    HubCore.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};

const getLogsAxiosInstance = () =>
    axios.create({
        baseURL: HubLogsRoot,
        headers: {
            "Content-Type": "application/json",
        },
    });

const getStoreAxiosInstance = (root) => {
    let instance = axios.create({
        baseURL: "https://api.mymorabaa.com",
        headers: {
            "Content-Type": "application/json",
        },
    });

    instance.interceptors.request.use(
        (config) => {
            config.headers["Authorization"] =
                "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2NTg5MjMwNDMsImh0dHA6Ly9zY2hlbWFzLm1pY3Jvc29mdC5jb20vd3MvMjAwOC8wNi9pZGVudGl0eS9jbGFpbXMvcm9sZSI6IlVzZXIiLCJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiaW5mb0Btb3JhYmFhLmNvbSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL25hbWVpZGVudGlmaWVyIjoiODc2YjMwZDUtMmU0NS00ZDVmLWE0NjAtNTc5MmU0OGQzZjZiIiwiUGFpcktleSI6ImIwZjhjZjhkLWFmNWMtNDcyMC1hOTZhLTA3YjAwOTM4OWQ0OSIsIklzVmVyaWZpZWQiOnRydWUsIklzTW9yYWJhYVN0YWZmIjpmYWxzZSwiVHlwZSI6ImFjY2VzcyIsIkFkZGl0aW9uYWxDbGFpbXMiOiJ7XCJidXNpbmVzc1JvbGVzXCI6XCJ7XFx1MDAyMjZhZmZjN2NlLTJhYjYtNDJlOC1hYjJlLWIwMjgxN2M5MGUwZVxcdTAwMjI6XFx1MDAyMk93bmVyXFx1MDAyMixcXHUwMDIyNGZhMjY4OWItZTRkYi00NWE1LWI3NDYtODQyZTVhZDU5NDc4XFx1MDAyMjpcXHUwMDIyT3duZXJcXHUwMDIyLFxcdTAwMjI1MjhmODViYy05NmQ3LTQyYmItOWNjNy00MmU2YzY2MTk0OGVcXHUwMDIyOlxcdTAwMjJPd25lclxcdTAwMjIsXFx1MDAyMmM3ZWU4ZWQ4LTYxNWItNDM0My1iYzY2LWJjNzBhZGMzYTkyMVxcdTAwMjI6XFx1MDAyMk93bmVyXFx1MDAyMixcXHUwMDIyYzRmOTI3ODYtODY3Yi00ZDVmLThlZjUtY2YyZmY0MWI2MTAwXFx1MDAyMjpcXHUwMDIyT3duZXJcXHUwMDIyLFxcdTAwMjJmMTc0NmE0MC0zY2I1LTQ1ODQtOTc1Zi1jMGRkYWFhYWVhNGNcXHUwMDIyOlxcdTAwMjJPd25lclxcdTAwMjIsXFx1MDAyMmY1MjIwZTYwLTI5OGYtNGVhNC1iM2NhLTRiNjVkZDg1MjIyMFxcdTAwMjI6XFx1MDAyMkFkbWluXFx1MDAyMn1cIn0iLCJJc0FkbWluIjpmYWxzZSwiTG9naW5zIjoiaW5mb0Btb3JhYmFhLmNvbSwrOTY0NzgzMDMwMzA1NSIsIm5iZiI6MTY1ODkyMzA0MywiZXhwIjoxNjU4OTMwMjQzLCJpc3MiOiJNb3JhYmFhIiwiYXVkIjoiTW9yYWJhYSJ9.uhVFXOj4mhY7LKYmMljcEXu89g6uWiWGZdLzquPQG4Er3HixGxq2G3g6jw2uJWo9QZ6PL65xqDLU-KIERTpq-OQosAxxdLPpI33cs0oC5suvy7oE6OAH6WcfUvvxp0rwbBVAFr7fjUhwRCV6ws-eeCLLCdTHZriWGlYcd9UYdyyH4mKHfvPksu7rZnTwFqt8cSKkX3zvKPFn78cNJOXysl9nbR-wPlJx0eI5Q__44AwX_Y7EapVNFWlYF74CMqrVH3PqXDc40KOzew3JAOv6t1mAcvewdy7U5Lmy-FtNDAvxEtfDAV8gNy2bS-cVlMkICkDiMkA72q_tTFCz4R9syQ";
            config.headers["X-Business-Id"] = "6affc7ce-2ab6-42e8-ab2e-b02817c90e0e";
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    return instance;
};

export const HubCore = getAxiosInstance(HubCoreRoot);
export const HubLogsAPI = getLogsAxiosInstance();
export const SotreAPI = getStoreAxiosInstance();
