import React from "react";

const DefaultNoData = () => {
    return (
        <div className="col-center col-span-full animate-bounce m-20">
            <p className="bg-white justify-center text-center px-9 py-12 rounded-full font-bold text-red-500">noData</p>
            <p className="w-full h-[1px] bg-red-700"></p>
        </div>
    );
};

export default DefaultNoData;
