import Formatter from "../Utils/_testFormatter";

const options = {};

const queries = {};

const onResult = (data) => {
    return data;
};

const onError = (e) => {
    // alert(JSON.stringify(e.message));
};
const url = "companies/dongles";

export default { url, queries, onResult, onError };
