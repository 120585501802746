import React from "react";
const TextCopyClickable = ({ info, infoTitle = "Dongle Id", onClick }) => {
    const [copied, setCopied] = React.useState(false);
    return (
        <p className="font-bold text row-center ">
            <span
                onClick={() => {
                    navigator.clipboard.writeText(info);
                    setCopied(true);
                    setTimeout(() => setCopied(false), 1500);
                }}
                className="material-icons pointer text-gray-300 text-[14px]">
                {copied ? "check" : "content_copy"}
            </span>
            <span className="text-[11px] pt-1 text-gray-400 px-1">{infoTitle} : </span>
            <span className="text-[15px] shadow-1 cursor-pointer px-2 rounded-md text-red-400 mx-1 py-0.5" onClick={onClick}>
                {info}
            </span>
        </p>
    );
};

export default TextCopyClickable;
