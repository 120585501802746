import React, { useEffect, useState } from "react";

const ItemsContainer = ({ Owl, ItemBuilder, className, NoData }) => {
    [Owl.items, Owl.setItems] = useState([]);

    useEffect(() => {
        console.log("Grid RD");
    });

    const setItem = (item) => {
        Owl.setItems((_items) => _items.map((_item) => (_item.id === item.id ? { ..._item, ...item } : _item)));
    };

    const set = (key, value, type = "set") => {
        if (key === "pageState") Owl.setPageState([value]);

        // if (type === "set") Owl.set((_data) => ({ ..._data, data }));
    };
    const set__ = (data, type = "set") => {
        if (type === "set") Owl.set((_data) => ({ ..._data, data }));
        else if (type === "push") Owl.set((_data) => ({ ..._data, items: [..._data.items, ...data] }));
        else if (type === "update")
            Owl.set((_data) => {
                data.map((itemToUpdate) => {
                    let index = _data.items.indexOf((_item) => _item.id === itemToUpdate.id);
                    _data.items[index] = { ..._data.items[index], ...itemToUpdate };
                });
                return { ..._data, items: data };
            });
    };

    // useEffect(() => {
    //     console.log(data);
    // }, [data]);

    // return <ItemBuilder  />;
    // return <div ref={ref}></div>;

    // const removeFromQuery = (keyToDelete) => {
    //     deepClean(base.queries, keyToDelete);
    //     onChange();
    // };

    // const deepClean = (val, keyToDelete) => {
    //     Object.entries([key,val]).map((val) => {
    //         val
    //         deepClean(key, val, keyToDelete);
    //     });

    //     console.log(keyToDelete, "HHHHHHHH", val);
    //     if (!val.type) deepClean(val, keyToDelete);
    //     else if (val.key === keyToDelete) {
    //         console.log("lol");
    //     }
    // };

    return (
        <div className={className}>
            {Owl.items ? Owl.items.map((item) => <ItemBuilder key={item.id} item={item} setItem={setItem} setItems={Owl.setItems} set={set} />) : NoData()}
        </div>
    );
};
export default ItemsContainer;

const dataSet = {};
