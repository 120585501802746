import React from "react";
import SearchingIcon from "../../Defaults/SearchingIcon";

const Searching = () => {
    return (
        <div className="searching-icon-container">
            <SearchingIcon />
        </div>
    );
    return (
        <div class="loadingio-spinner-magnify-wmam82hzz8 left-0 right-0 fixed">
            <div class="ldio-icon">
                <div>
                    <div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Searching;
