const DropDown = ({ value, storageKey, setValue, opstions, bg = "#343849" }) => (
    <select
        className="drowp-field"
        style={{ backgroundColor: bg }}
        value={value}
        onChange={({ target }) => {
            if (storageKey) localStorage.setItem(storageKey, target.value);
            setValue(target.value);
        }}>
        {opstions.map((item) => (
            <option value={item.id} key={item.id}>
                {item.title}
            </option>
        ))}
    </select>
);
export default DropDown;
