import React from "react";

const CountHolder = ({ data }) => (
    <div className="row-center font-bold col-span-full bg-white rounded text-xl red-text p-2" style={{ maxWidth: 280 }}>
        <span className="text-[12px] pl-2 text-gray-400">العدد الكلي :</span>
        {data.loading ? (
            <p className="loading-count">
                <span />
                <span />
                <span />
                <span />
            </p>
        ) : (
            <span>{data.count}</span>
        )}
    </div>
);

export default CountHolder;
