import React, { useState, useEffect, useMemo } from "react";
import { HubLogsAPI } from "../Api";
import ToggleButton from "../Components/ToggleButton";
import DongleCard from "../Components/DongleCard";
import DropDown from "../Components/DropDown";
import Form from "../Components/Form";
import InputText from "../Components/InputText";
import Loader from "../Components/Loader";
import LogScroller from "../Components/LogScroller";
import Options from "../Utils/Globals";
import StorageQueryHolder from "../Components/StorageQueryHolder";
import Button from "../Components/Button";
import UrlsGenerater from "../Utils/UrlsGenerater";

const DonglesView = () => {
    const [dongles, setDongles] = useState([]);
    const [loading, setLoading] = useState(true);
    const [totalsCount, setTotalsCount] = useState("");
    const [limit, setLimit] = useState(localStorage.getItem("dongles-limit") || 25);
    const [sort, setSort] = useState(localStorage.getItem("dongles-sort") || "createdAt");
    const [desc, setDesc] = useState(localStorage.getItem("desc-dongles") !== "0");
    const [showBlockedDonlge, setShowBlockedDonlge] = useState(localStorage.getItem("blocked-dongles") !== "0");
    const [dongleId, setDongleId] = useState("");
    const [companyId, setCompanyId] = useState("");
    const [screenWorkingArea, setScreenWorkingArea] = useState("");
    const [dongleType, setDongleType] = useState(localStorage.getItem("dongle-type") || "");
    const [storageQuery, setStorageQuery] = useState(JSON.parse(localStorage.getItem("dongles-query")) || "");
    const [machineName, setMachineName] = useState("");
    const [isDeleted, setIsDeleted] = useState(JSON.parse(localStorage.getItem("dongles-isDeleted")) || false);

    const selectedDongles = useMemo(() => dongles.filter((o) => o.selected).map((d) => d.id), [dongles]);
    const getDongles = async (clear = false) => {
        const random = setRequestId();
        const { data } = await HubLogsAPI.get(getUrl(clear));
        if (requestId !== random) return;
        setDongles(clear ? data.data : dongles.concat(data.data));
        setTotalsCount(data.count);
        setLoading(false);
    };
    const getUrl = (clear) => {
        const query = {
            limit,
            dongleId,
            companyId,
            isMain: dongleType,
            isBlocked: showBlockedDonlge,
            offset: clear ? 0 : dongles.length,
            screenWorkingArea,
            storageQuery,
            machineName,
            sort,
            desc,
            isDeleted: !!isDeleted,
        };
        return UrlsGenerater(query, "companies/dongles");
    };
    useEffect(() => {
        search();
    }, [dongleType, desc, sort, showBlockedDonlge, isDeleted, limit]);

    const search = () => {
        setLoading(true);
        getDongles(true);
    };

    const onDongleClicked = (dongleId) => {
        setDongles((_dongles) => _dongles.map((_dongle) => (_dongle.id === dongleId ? { ..._dongle, selected: !_dongle.selected } : _dongle)));
    };
    const deleteDongels = async () => {
        await HubLogsAPI.delete("companies/dongles", { data: selectedDongles });
        search();
    };

    const toggleAllDongles = (selected) => {
        setDongles((_dongles) => _dongles.map((_dongle) => ({ ..._dongle, selected })));
    };

    const blockDongles = async () => {
        const dongleIds = [...selectedDongles];
        await HubLogsAPI.patch("companies/dongles/block", dongleIds);
        search();
    };

    return (
        <LogScroller canLoadMore={canLoadMore} fetchData={getDongles}>
            <Form submit={search} loading={loading} count={totalsCount}>
                <div className="wrap-conatiner">
                    <DropDown opstions={Options.DonglesSort} value={sort} storageKey="dongles-sort" setValue={setSort} />
                    <DropDown value={limit} opstions={Options.PagesSize} placeholder="حجم الصفحة" storageKey="dongles-limit" setValue={setLimit} />
                    <DropDown value={dongleType} opstions={Options.DonglesType} placeholder="نوع الدنكل" storageKey="dongle-type" setValue={setDongleType} />
                    <ToggleButton title="ترتيب تنازلي" value={desc} setValue={setDesc} storge="desc-dongles" />
                    <ToggleButton title="الدناكل المحضورة" value={showBlockedDonlge} setValue={setShowBlockedDonlge} storge="blocked-dongles" />
                    <ToggleButton title="المحذوفة" value={isDeleted} setValue={setIsDeleted} storge="dongles-isDeleted" />
                </div>
                <div className="wrap-conatiner">
                    <InputText placeholder="Dongle Id" value={dongleId} setValue={setDongleId} />
                    <InputText placeholder="Company Id" value={companyId} setValue={setCompanyId} />
                    <InputText placeholder="ابعاد الشاشة" value={screenWorkingArea} setValue={setScreenWorkingArea} />
                    <InputText placeholder="اسم الجهاز" value={machineName} setValue={setMachineName} />
                </div>
            </Form>

            <StorageQueryHolder
                extraClass="sticky top-0"
                storageQuery={storageQuery}
                close={() => {
                    setStorageQuery("");
                    // setSelectedDongles([]);
                }}>
                {selectedDongles.length > 0 && (
                    <>
                        <div className="flex flex-wrap gap-2 p-3 max-w-2xl py-2">
                            {selectedDongles.length < dongles.length && <Button onClick={toggleAllDongles} title="تحديد الكل" />}
                            <Button onClick={() => toggleAllDongles(false)} title={selectedDongles.length > 1 ? "الغاء تحديد الكل " : "الغاء التحديد"} />
                            <Button onClick={blockDongles} title="حضر" />
                            <Button onClick={deleteDongels} title="حذف" />
                        </div>
                    </>
                )}
            </StorageQueryHolder>

            {loading ? (
                <Loader />
            ) : (
                <div className="Grid">
                    {dongles.map((dongle) => (
                        <DongleCard onDongleClicked={onDongleClicked} key={dongle.id} dongle={dongle} />
                    ))}
                </div>
            )}
        </LogScroller>
    );
};

export default DonglesView;

let canLoadMore = true;
let requestId = null;
function setRequestId() {
    const random = Math.random();
    requestId = random;
    return random;
}
