import React, { useEffect, useMemo, useState } from "react";
import { HubCore, HubLogsAPI } from "../Api";
import Options from "../Utils/Globals";
import DropDown from "../Components/DropDown";
import LogScroller from "../Components/LogScroller";
import PopupContainer from "../Components/Dialog";
import Loader from "../Components/Loader";
import Form from "../Components/Form";
import Formatter from "../Utils/Formatter";
import ToggleButton from "../Components/ToggleButton";
import InputText from "../Components/InputText";
import UrlsGenerater from "../Utils/UrlsGenerater";
import StorageQueryHolder from "../Components/StorageQueryHolder";
import CompanyCard from "../Components/CompanyCard";
import Button from "../Components/Button";
import TextCopy from "../Components/TextCopy";
const CompaniesDetails = () => {
    const [companies, setCompanies] = useState([]);
    const [limit, setLimit] = useState(localStorage.getItem("logs-limit") || 25);
    const [sort, setSort] = useState(localStorage.getItem("compainesSort") || "lastLogAt");
    const [name, setname] = useState("");
    const [businessTitle, setBusinessTitle] = useState("");
    const [source, setSource] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [totalCounts, setTotalCounts] = useState("");
    const [version, setVersion] = useState("");
    const [dongles, setDongles] = useState(false);
    const [dongle, setDongle] = useState("");
    const [desc, setDesc] = useState(localStorage.getItem("companies-desc") !== "0");
    const [storageQuery, setStorageQuery] = useState(JSON.parse(localStorage.getItem("companies-query")) || false);
    const [isDeleted, setIsDeleted] = useState(JSON.parse(localStorage.getItem("companies-isDeleted")) || false);
    const [progressing, setProgressing] = useState(false);
    const [defualtCompany, setDefualtCompany] = useState(storageQuery.defualtCompany);

    const selectedCompanies = useMemo(() => companies.filter((company) => company.selected).map((co) => co.id), [companies]);

    const fetchCompaines = async (clear = false) => {
        const random = setRequestId();
        const { data } = await HubLogsAPI.get(getUrl(clear));
        setIsLoading(false);
        if (random !== requestId) return;

        var _companies = data.data;
        setTotalCounts(Formatter(data.count));
        canLoadMore = _companies.length >= limit;

        _companies.forEach((item) => {
            if (item.errorsCount) {
                item.lastErrorAt = new Date(item.lastErrorAt).toLocaleString().split(",");
                item.lastErrorVersion -= 70000;
            }
            if (item.lastLogAt) {
                item.lastLogAt = new Date(item.lastLogAt).toLocaleString().split(",");
                item.lastVersion -= 70000;
            }

            item.dongles.forEach((o) => {
                if (o.screenWorkingArea) {
                    try {
                        let sizeArr = o.screenWorkingArea.split("---");
                        if (sizeArr) o.screenWorkingArea = `${sizeArr[1].split(":")[1]} * ${sizeArr[0].split(":")[1]}`;
                    } catch {}
                }
            });
        });
        setCompanies(clear ? _companies : [...companies, ..._companies]);
    };

    function getUrl(clear) {
        let query = {
            initalSort: Options.CompanySort[2].id,
            offset: clear ? 0 : companies.length,
            limit,
            name,
            businessTitle,
            version,
            source,
            sort,
            desc,
            dongle,
            isDeleted: !!isDeleted,
        };
        return UrlsGenerater(query, "/companies");
    }

    useEffect(() => {
        search();
    }, [sort, desc, storageQuery, limit, isDeleted]);

    const [messageToView, setMessageToView] = useState({
        message: false,
        animation: "",
    });

    const GetMessageById = (id) => {
        setProgressing(true);
        HubLogsAPI.get(`/logs/messages/${id}`).then(({ data }) => {
            setProgressing(false);
            setMessageToView({
                message: [data.content],
                infoTitle: "Content",
                animation: "fade-in",
            });
        });
    };

    const handleCloseDongles = () => {
        setDongles({ data: dongles.data, animation: "fade-out", pos: dongles.pos });
        setTimeout(() => {
            setDongles(false);
        }, 140);
    };
    const handleOpenDongles = (target, data) => {
        let { y, x } = target.getBoundingClientRect();
        let top = y > 400 ? y - (90 + data.length * 45) : y;
        let left = x > 1200 ? x - 620 : x;

        setDongles({
            data: data.sort((o) => (o.isMain ? -1 : 1)),
            animation: "fade-in",
            pos: { top, left },
        });
    };
    const handleOpenUsers = (data) => {
        setMessageToView({
            message: data,
            infoTitle: "User",
            animation: "fade-in",
        });
    };

    const handleCloseMessage = () => {
        setMessageToView({ message: messageToView.message, animation: "fade-out" });
        setTimeout(() => {
            setMessageToView({ message: "", animation: "" });
        }, 140);
    };

    const search = () => {
        setIsLoading(true);
        fetchCompaines(true);
    };

    const onCompanyClicked = (companyId) => {
        setCompanies((_companies) => _companies.map((_company) => (_company.id === companyId ? { ..._company, selected: !_company.selected } : _company)));
    };

    const margeCompanies = () => {
        const data = {
            mainSoftwareUUId: defualtCompany || selectedCompanies[0],
            softwareUUIdIds: selectedCompanies,
        };
        HubLogsAPI.patch(`/companies/marge`, data)
            .then(() => {
                search();
            })
            .catch((err) => {
                console.error(err);
            });
    };
    const deleteCompaines = () => {
        HubLogsAPI.delete("/companies", { data: selectedCompanies })
            .then(() => {
                search();
            })
            .catch((err) => {
                console.error(err);
            });
    };
    const setDefaultCompany = () => {
        HubLogsAPI.patch(`/companies/default-dongle`, {
            dongleId: storageQuery.query.dongleId,
            companyId: selectedCompanies[0],
        }).then(() => {
            setDefualtCompany(selectedCompanies[0]);
        });
    };

    const toggleAllCompanies = (selected = true) => {
        setCompanies((_companies) => _companies.map((_company) => ({ ..._company, selected })));
    };

    const showBusiness = (company) => {
        setProgressing(true);

        HubCore.get(`/businesses/${company.businessId}`)
            .then(({ data }) => {
                setMessageToView({
                    message: [
                        //
                        `الاسم : ${data.title}`,
                        `العنوان : ${data.address}`,
                        `عدد الدناكل : ${data.dongles.length}`,
                        ` ${data.businessCategory.title}`,
                        `${data.supportPIN} : supportPIN`,
                        // `AgentId : ${data.agentId}`,
                    ],
                    infoTitle: "_",
                    animation: "fade-in",
                });
            })
            .finally(() => {
                setProgressing(false);
            });
    };

    return (
        <>
            {messageToView.message && (
                <PopupContainer animation={messageToView.animation} close={handleCloseMessage}>
                    <div className="p-10" onMouseLeave={handleCloseMessage}>
                        <div className="popup-Message LogScroller relative">
                            {messageToView.message.map((info, i) => (
                                <TextCopy key={i} info={info} infoTitle={messageToView.infoTitle} />
                            ))}
                        </div>
                    </div>
                </PopupContainer>
            )}
            {dongles.data && (
                <PopupContainer animation={dongles.animation} close={handleCloseDongles}>
                    <div className="py-9  " style={{ top: dongles.pos.top, left: dongles.pos.left }} onMouseLeave={handleCloseDongles}>
                        <div className="popup-Message shadow-1">
                            {dongles.data.map((dongle, i) => (
                                <div key={i} className="select-none flexRow justify-between border-b py-2">
                                    <TextCopy info={dongle.id} />
                                    <p className={`flexRow items-center mr-20 ${dongle.isMain ? "text-red-700" : "text-green-700"}`}>
                                        {dongle.screenWorkingArea && <span className="ml-3 text-gray-600 text-sm">({dongle.screenWorkingArea})</span>}
                                        <span>{dongle.machineName}</span>
                                        <span class="material-icons text-2xl px-3">computer</span>
                                    </p>
                                </div>
                            ))}
                        </div>
                    </div>
                </PopupContainer>
            )}

            <LogScroller fetchData={fetchCompaines} canLoadMore={canLoadMore} loading={progressing}>
                <Form submit={search} count={totalCounts} loading={isLoading}>
                    <div className="wrap-conatiner">
                        <DropDown opstions={Options.CompanySort} placeholder="الترتيب" value={sort} storageKey="compainesSort" setValue={setSort} />
                        <DropDown value={limit} opstions={Options.PagesSize} placeholder="حجم الصفحة" storageKey="logs-limit" setValue={setLimit} />
                        <ToggleButton title="ترتيب تنازلي" value={desc} setValue={setDesc} storge="companies-desc" />
                        <ToggleButton title="المحذوفة" value={isDeleted} setValue={setIsDeleted} storge="companies-isDeleted" />
                    </div>
                    <div className="wrap-conatiner">
                        <InputText placeholder="اسم الشركة" value={name} setValue={setname} />
                        <InputText placeholder="الاسم بالدنكل" value={businessTitle} setValue={setBusinessTitle} />
                        <InputText placeholder="المصدر" value={source} setValue={setSource} />
                        <InputText placeholder="الايقونة" value={version} setValue={setVersion} />
                        <InputText placeholder="الدنكل" value={dongle} setValue={setDongle} />
                    </div>
                    <a href="/reports/dongles" target="_blank" className="font-bold text-blue-700 cursor-pointer" style={{ textDecorationLine: "underline" }}>
                        الدناكل
                    </a>
                </Form>
                <StorageQueryHolder
                    extraClass="sticky top-0"
                    storageQuery={storageQuery}
                    close={() => {
                        setStorageQuery("");
                        localStorage.removeItem("companies-query");
                        // setSelectedCompanies([]);
                    }}>
                    {selectedCompanies.length > 0 && (
                        <>
                            <div className="flex flex-wrap gap-2 p-3 max-w-2xl border-t-2 py-2">
                                {selectedCompanies.length < companies.length && <Button onClick={toggleAllCompanies} title="تحديد الكل" />}
                                <Button
                                    onClick={() => toggleAllCompanies(false)}
                                    title={selectedCompanies.length > 1 ? "الغاء تحديد الكل " : "الغاء التحديد"}
                                />
                                <Button onClick={deleteCompaines} title="حذف" />
                                {selectedCompanies.length > 1 ? (
                                    <Button onClick={margeCompanies} title={selectedCompanies.length > 2 ? "دمج الشركات" : " دمج الشركتين"} />
                                ) : (
                                    companies.length > 1 &&
                                    storageQuery.query?.dongleId &&
                                    defualtCompany !== selectedCompanies[0] && <Button onClick={setDefaultCompany} title="جعلها الشركة المعتمدة" />
                                )}
                            </div>
                        </>
                    )}
                </StorageQueryHolder>

                {isLoading ? (
                    <Loader />
                ) : (
                    <div className={`Grid ${isDeleted && "deleted"}`}>
                        {companies.map((company) => (
                            <CompanyCard
                                key={company.id}
                                company={company}
                                handleOpenDongles={handleOpenDongles}
                                handleOpenUsers={handleOpenUsers}
                                GetMessageById={GetMessageById}
                                onCompanyClicked={onCompanyClicked}
                                //selectedCompanies={selectedCompanies}
                                defualtCompany={defualtCompany}
                                showBusiness={showBusiness}
                            />
                        ))}
                    </div>
                )}
            </LogScroller>
        </>
    );
};

export default CompaniesDetails;
let canLoadMore = true;
let requestId = null;
function setRequestId() {
    const random = Math.random();
    requestId = random;
    return random;
}
