import React from "react";
const String = ({ prop }) => {
    console.log("String RD");

    // return (
    //     <div className="owl-label-conatiner">
    //         <input type="text" className="owl-date" defaultValue={prop.value} onChange={onChange} />
    //         <p className="owl-label">{prop.title} </p>
    //     </div>
    // );
    const ref = React.useRef();
    return (
        <div key={prop.key}>
            <input
                ref={ref}
                type="text"
                name={prop.key}
                placeholder={prop.title}
                onChange={({ target }) => {
                    checkChange(target, prop, prop.onChange);
                }}
                className={`owl-input ${prop.className}`}
            />
            {/* <span
                className="clear-icon"
                onClick={() => {
                    prop.value = "";
                    ref.current.value = "";
                    onChange();
                }}>
                x
            </span> */}
        </div>
    );
};

export default String;

const checkChange = (target, prop, onChange) => {
    let pastValue = target.value;
    setTimeout(() => {
        if (pastValue !== target.value) return;
        {
            prop.value = target.value;
            prop.clear = () => {
                target.value = "";
            };
            onChange();
        }
    }, 700);
};
