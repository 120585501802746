import React from "react";
import CountHolder from "./CountHolder";
let _loading = true;
const Form = ({ children, submit, className, count, loading }) => {
    // const [countLoading, setCountLoading] = useState("");
    // const _countLoading = (text = "") => {
    //     setTimeout(() => {
    //         text += ".";
    //         if (text === ".....") text = "";
    //         setCountLoading(text);
    //         if (_loading) _countLoading(text);
    //         else setCountLoading("");
    //     }, 220);
    // };

    // useEffect(() => {
    //     _loading = loading;
    //     _countLoading();
    // }, [loading]);

    const onSubmit = (e) => {
        e.preventDefault();
        submit();
        return false;
    };

    const _className = `flex bg-[#fafafa] flex-col px-8 py-2  rounded-xl shadow-1 my-4 py-4 gap-4 ${className}`;
    return (
        <form className={_className} onSubmit={onSubmit}>
            {children}
            <CountHolder count={count} loading={loading} />
            <input className="h-0" type="submit" />
        </form>
    );
};

export default Form;
