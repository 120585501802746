import axios from "axios";

const Options = {
    Levels: [
        { title: "All Levels", id: "" },
        { title: "Debug", id: 0 },
        { title: "Info", id: 1 },
        { title: "Warn", id: 2 },
        { title: "Error", id: 3 },
        { title: "Fatal", id: 4 },
    ],

    PagesSize: [
        { title: "25", id: 25 },
        { title: "50", id: 50 },
        { title: "75", id: 75 },
        { title: "100", id: 100 },
    ],

    BaseLogSort: [
        { title: "التاريخ", id: "onlineId" },
        { title: "الايقونة", id: "versionId" },
    ],

    CompanySort: [
        { title: "التاريخ", id: "createdAt" },
        { title: "الايقونة", id: "lastVersion" },
        { title: "اخر لوك", id: "lastLogAt" },
        { title: "اخر ايرور", id: "lastErrorAt" },
        { title: "عدد اللوك", id: "logsCount" },
        { title: "عدد الايرور", id: "errorsCount" },
        { title: "عدد الدناكل", id: "donglesCount" },
        { title: "عدد المستخدمين", id: "usersCount" },
    ],

    WithoutErrorStatus: [
        { title: "_", id: "" },
        { title: "بدون To Do", id: 0 },
        { title: "بدون Solving", id: 1 },
        { title: "بدون Pending", id: 2 },
        { title: "بدون Solved", id: 3 },
        { title: "بدون Ignored", id: 4 },
        { title: "بدون Cannot Reproduce", id: 5 },
    ],

    FilterErrorStatus: [
        { title: "All Status", id: "" },
        { title: "To Do", id: 0 },
        { title: "Solving", id: 1 },
        { title: "Pending", id: 2 },
        { title: "Solved", id: 3 },
        { title: "Ignored", id: 4 },
        { title: "Cannot Reproduce", id: 5 },
    ],

    ErrorSort: [
        { title: "التاريخ", id: "lastUpdate" },
        { title: "الايقونة", id: "maxVersion" },
        { title: "التكرار", id: "numberOfRepetitions" },
    ],

    ErrorStateus: [
        { title: "To Do", id: 0 },
        { title: "Solving", id: 1 },
        { title: "Pending", id: 2 },
        { title: "Solved", id: 3 },
        { title: "Ignored", id: 4 },
        { title: "Cannot Reproduce", id: 5 },
    ],
    DonglesType: [
        { title: "كل الدناكل", id: "" },
        { title: "رئيسي", id: true },
        { title: "فرعي", id: false },
    ],

    DonglesSort: [
        { title: "التاريخ", id: "createdAt" },
        { title: "عدد الشركات المستخدمة", id: "numberOfSoftwareUUIds" },
    ],

    ErrorColors: ["#a875ff", "#ff4081", "#e50000", "#6BC950", "#D3D3D3", "#2ECD6F"],
};

export const getAxiosInstance = ({ baseURL, token, appPackage, other }) => {
    const headers = {
        "Content-Type": "application/json",
    };
    if (appPackage) headers["App-Package"] = appPackage;
    if (other) headers[other.key] = other.value;

    let instance = axios.create({
        baseURL,
        headers,
    });
    instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    return instance;
};

export const hasValue = (value) => [undefined, null, -1, ""].includes(value) === false;
export default Options;
