import React from "react";
import { HubLogsAPI } from "../Api";
import DefaultItemBuilder from "../Defaults/DefaultItemBuilder";
import JsonToView from "../Defaults/JsonToView";
import DefaultErrorBuilder from "./Components/DefaultErrorBuilder";
import DefaultNoData from "./Components/DefaultNoData";
import MainLoader from "./Components/MainLoader";
import HeaderContainer from "./Components/_testHeaderContainer";
import ItemsContainer from "./Components/_testItemsContainer";
import LoaderContainer from "./Components/_testLoaderContainer";
import QueryContainer from "./Components/_testQueryContainer";
import OwlScroller from "./OwlScroller";
import PageStateProvider from "./PageStateKit/PageStateProvider";
import _testOwlMethods from "./Utils/_testOwlMethods";

const Owl = {};

const fragment = () => <></>;
const OwlConatiner = ({
    url,
    queries = null,
    onResult = null,
    onError = null,
    loader = MainLoader,
    noData = DefaultNoData,
    errorBuilder = DefaultErrorBuilder,
    child = fragment,
    itemBuilder = DefaultItemBuilder,
    itemsConatinerClass = "owl-grid",
    api = HubLogsAPI,
    queryContainerClass = "owl-query-conainer",
    children,
}) => {
    console.log("OwlConatiner RDS");

    const init = async () => {
        await _testOwlMethods.getOwl(Owl, { url, queries, onResult, onError, api });
    };

    React.useMemo(() => {
        console.log("Effect Owl Container RD");
        init();
    }, []);

    const onChange = (child, clear = false) => {
        console.log("HHHHH", child);
        if (child.clear || true)
            Owl.setQueryParams((_queryParams) => {
                if (clear) delete _queryParams[child.key];
                else _queryParams[child.key] = child;
                return { ..._queryParams };
            });
        else Owl.queryParams[child.key] = child;
        Owl.search();
    };

    return (
        <OwlScroller Owl={Owl}>
            {children}
            {/* <JsonToView item={Owl} /> */}
            {queries && <QueryContainer className={queryContainerClass} Owl={Owl} onChange={onChange} children={queries} />}

            <HeaderContainer Owl={Owl} HeaderBuilder={child} />
            <PageStateProvider Owl={Owl} Loader={loader} />
            <ItemsContainer
                Owl={Owl}
                NoData={noData}
                onChange={onChange}
                ItemBuilder={itemBuilder}
                ErrorBuilder={errorBuilder}
                className={itemsConatinerClass}
            />
        </OwlScroller>
    );
};

export default OwlConatiner;
