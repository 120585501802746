import React from "react";
import TestEndpoints from "../Defaults/TestEndpoints";

import OwlConatiner from "../_testing/OwlConatiner";
import Logs from "../_testing/ViewsData/Logs";

const TEST = () => {
    console.log("BaseLogsView RD");
    const [owl, setOwl] = React.useState(false);

    return owl ? (
        <OwlConatiner
            url={owl.endpoint}
            queries={Logs.querySections}
            // onResult={onResult}
            // onError={Logs.onError}
            // loader={Loader}
            // noData={NoData}
            // child={Header}
            // errorBuilder={ErrorBuilder}
            // itemBuilder={ItemBuilder}
            // itemsConatinerClass="owl-grid"
            // querySectionClass="owl-query-section p-2 gap-2 m-2 shadow-1"
            // queryContainerClass="owl-query-conainer"
            api={owl.api}>
            <TestEndpoints setOwl={setOwl} owl={owl} />
        </OwlConatiner>
    ) : (
        <>
            <TestEndpoints setOwl={setOwl} />
        </>
    );
};

export default TEST;
