import React from "react";
const getClass = (prop) => "owl-toggleed-bg" + (prop.value ? " owl-toggleed" : "");
const Boolean = ({ prop }) => {
    console.log("Boolean RD");
    // console.log("SSS", prop);
    const ref = React.useRef(null);
    React.useMemo(() => {
        setTimeout(() => {
            // alert(JSON.stringify(prop.value));
            // ref.current.className = `owl-toggleed-bg ${prop.value ? " owl-toggleed" : ""}`; // getClass(prop);
        }, 1000);
    }, []);
    return (
        <div className={`owl-toggle-button ${prop.className}`} key={prop.key}>
            <p
                ref={ref}
                onClick={(e) => {
                    prop.value = !prop.value;
                    e.currentTarget.className = getClass(prop);
                    if (prop.storageKey) localStorage.setItem(prop.storageKey, prop.value);
                    prop.onChange();
                }}
                className={getClass(prop)}>
                <span />
            </p>
            <p className="font-bold owl-text-xs">{prop.title}</p>
        </div>
    );
};

export default Boolean;
