import { Owl } from "./OwlConatiner";

const OwlScroller = ({ Owl, children }) => {
    console.log("Scroller RD");
    const containerScrolled = ({ target }) => {
        if (Owl.canLoadMore && target.scrollHeight - target.scrollTop < target.clientHeight + 100) {
            console.log({ "Owl[base].canLoadMore": Owl.canLoadMore });
            Owl.fetch("s");
        }
    };
    return (
        <div onScroll={containerScrolled} className={`overflow-auto scroller h-screen p-3 ${Owl.loading && " cursor-wait "}`}>
            {children}
        </div>
    );
};

export default OwlScroller;
