import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import TestRouts from "./logsReporting/TestRouts";

const App = () => {
    console.log("App RD");
    return (
        <Router>
            <TestRouts />
        </Router>
    );
};

export default App;
