import React from "react";
import DefaultItemBuilder from "../../Defaults/DefaultItemBuilder";

const DefaultErrorBuilder = ({ data }) => {
    return (
        <>
            <div className="col-center  col-span-full">
                <p className="text-white text-center animate-bounce rounded-full font-bold bg-red-500" style={{ padding: "40px 30px", opacity: 0.3 }}>
                    Error!
                </p>
            </div>
            <DefaultItemBuilder item={data.error} />
        </>
    );
};

export default DefaultErrorBuilder;
