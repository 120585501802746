import _testFormateDate from "../Utils/_testFormateDate";

const options = {
    ErrorColors: ["#a875ff", "#ff4081", "#e50000", "#6BC950", "#D3D3D3", "#2ECD6F"],

    Sources: [
        { title: "All Sources", id: "" },
        { title: "Morabaa7", id: "Morabaa7", className: "text-cyan-600" },
    ],

    WithoutErrorStatus: [
        { title: "_", id: "" },
        { title: "بدون To Do", id: 0, className: "error-status-color-0" },
        { title: "بدون Solving", id: 1, className: "error-status-color-1" },
        { title: "بدون Pending", id: 2, className: "error-status-color-2" },
        { title: "بدون Solved", id: 3, className: "error-status-color-3" },
        { title: "بدون Ignored", id: 4, className: "error-status-color-4" },
        { title: "بدون Cannot Reproduce", id: 5, className: "error-status-color-5" },
    ],

    FilterErrorStatus: [
        { title: "All Status", id: "" },
        { title: "To Do", id: 0, className: "error-status-color-0" },
        { title: "Solving", id: 1, className: "error-status-color-1" },
        { title: "Pending", id: 2, className: "error-status-color-2" },
        { title: "Solved", id: 3, className: "error-status-color-3" },
        { title: "Ignored", id: 4, className: "error-status-color-4" },
        { title: "Cannot Reproduce", id: 5, className: "error-status-color-5" },
    ],

    ErrorSort: [
        { title: "التاريخ", id: "lastUpdate" },
        { title: "الايقونة", id: "maxVersion" },
        { title: "التكرار", id: "numberOfRepetitions" },
    ],

    ErrorStateus: [
        { title: "To Do", id: 0 },
        { title: "Solving", id: 1 },
        { title: "Pending", id: 2 },
        { title: "Solved", id: 3 },
        { title: "Ignored", id: 4 },
        { title: "Cannot Reproduce", id: 5 },
    ],
};

// initalSort: Options.ErrorSort[0].id,
// offset: clear === true ? 0 : erorrs.length,
// limit,
// content,
// status,
// sort,
// desc,
// withoutThisStatus: withoutThisStatus === status ? undefined : withoutThisStatus,

const queries = [
    {
        children: [
            { key: "sort", type: "Options", title: "الترتيب", options: options.ErrorSort, storageKey: "error-sort" },
            { key: "status", type: "Options", title: "الحالة", options: options.FilterErrorStatus, storageKey: "error-status" },
            { key: "withoutErrorStatus", type: "Options", title: "بدون حالة", options: options.WithoutErrorStatus, storageKey: "error-without-status" },
        ],
    },
    {
        children: [
            { key: "source", type: "Options", title: "المصدر", options: options.Sources },
            { key: "companyName", type: "String", title: "اسم الشركة", value: "" },
            { key: "content", type: "String", title: "الرسالة", value: "" },
        ],
    },
];

const onResult = (data) => {
    data.data.forEach((item) => {
        item.color = options.ErrorColors[item.status];
        item.statusStr = options.ErrorStateus[item.status].title;
        item.date = _testFormateDate(item.createdAt);
        item.versions = item.versions?.map((version) => (version -= 70000));
        item.maxVersion -= 70000;
    });

    return data;
};
const onError = () => {};
export default { url: "errors", queries, onResult, onError };
export const Optionss = options;
