import React from "react";
import Label from "./Label";
import LightButton from "./LightButton";
import TextCopy from "./TextCopy";
import CheckBox from "./CheckBox";
import TextCopyClickable from "./TextCopyClickable";
import Selectable from "../_testing/Components/_testSelectable";

const labelsColor = "#a2e051";

const CompanyCard = ({ item, setItem, handleOpenDongles, handleOpenUsers, GetMessageById, onCompanyClicked, defualtCompany, showBusiness }) => {
    return (
        <div className="item-builder">
            <div className="flex flex-wrap border-b gap-3 p-3">
                <Selectable extraClass="" item={item} setItem={setItem} />
                {item.businessTitle ? <TextCopy info={item.businessTitle} infoTitle="Dongle" /> : <TextCopy info={item.dongle?.id} />}
                {item.businessId ? (
                    <TextCopyClickable info={item.name || item.buseinessId} infoTitle="Company" onClick={() => showBusiness(item)} />
                ) : (
                    item.name && <TextCopy info={item.name} infoTitle="Company" onClick={() => showBusiness(item)} />
                )}
                {item.address && <span className="font-bold text-lg">{item.address}</span>}
            </div>
        </div>
    );
    return (
        <div className={`logs-card font-bold`}>
            <div className="flex flex-wrap border-b gap-3 p-3">
                {item.businessTitle ? <TextCopy info={item.businessTitle} infoTitle="Dongle" /> : <TextCopy info={item.dongle?.id} />}
                {item.businessId ? (
                    <TextCopyClickable info={item.name || item.buseinessId} infoTitle="Company" onClick={() => showBusiness(item)} />
                ) : (
                    item.name && <TextCopy info={item.name} infoTitle="Company" onClick={() => showBusiness(item)} />
                )}
                {item.address && <span className="font-bold text-lg">{item.address}</span>}

                <CheckBox
                    extraClass="mr-auto"
                    value={item.selected}
                    setValue={() => {
                        onCompanyClicked(item.id);
                    }}
                />
            </div>
            {defualtCompany === item.id && <p className="bg-red-400 text-white text-center text-sm -mx-5">{"الشركة المعتمدة"}</p>}
            <div className={`flex-wrap row justify-end gap-3 p-4`}>
                <div className="min-w-[150px] col gap-3">
                    <p className="text-gray-300 pl-1 text-left" style={{ letterSpacing: 4 }}>
                        {item.source}
                    </p>
                    <Label
                        title="Dongles"
                        onClick={({ target }) => {
                            if (item.dongles.length > 0) handleOpenDongles(target, item.dongles);
                        }}
                        info={` ${item.donglesCount} / ${item.dongles?.length} `}
                    />
                    <Label
                        title="Users"
                        info={` ${item.usersCount} / ${item.users?.length} `}
                        onClick={() => {
                            if (item.users?.length > 0) handleOpenUsers(item.users);
                        }}
                        onMouseOver={handleOpenUsers}
                    />
                </div>
                <div className="col gap-3 ml-auto">
                    {item.lastLogAt && (
                        <LightButton
                            createdAt={item.lastLogAt}
                            version={item.lastVersion}
                            logsCount={item.logsCount}
                            onClick={() => GetMessageById(item.lastLogMessageId)}
                            onTotalClicked={() => pushToGenralLogs(item)}
                        />
                    )}
                    {item.errorsCount > 0 && (
                        <LightButton
                            color={1}
                            createdAt={item.lastLogAt}
                            version={item.lastErrorVersion}
                            logsCount={item.errorsCount}
                            totalTitle="Total Errors"
                            onClick={() => GetMessageById(item.lastErrorMessageId)}
                            onTotalClicked={() => pushToGenralLogs(item, true)}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

const pushToGenralLogs = (item, error) => {
    let title = item.name || item.businessTitle;
    let query = { softwareUUId: item.id };
    if (error) query.level = "3";
    localStorage.setItem("logs-query", JSON.stringify({ title, query }));
};

export default CompanyCard;
