import React from "react";

const CountHolder = ({ count, loading }) => (
    <div className="font-bold text-xl red-text flex  pb-2">
        <span className="gray-text text-sm mt-1.5 pl-2">العدد الكلي :</span>
        {loading ? (
            <p className="loading-count">
                <span />
                <span />
                <span />
                <span />
            </p>
        ) : (
            <span>{count}</span>
        )}
    </div>
);

export default CountHolder;
