import Formatter from "../Utils/_testFormatter";
const LevelColors = ["#A35200", "#1890FF", "#FAAD14", "#F5222D", "#FF1010"];

const options = {
    Levels: [
        { title: "All Levels", id: "" },
        { title: "Debug", id: 0, className: "logs-level-debug" },
        { title: "Info", id: 1, className: "logs-level-info" },
        { title: "Warn", id: 2, className: "logs-level-warn" },
        { title: "Error", id: 3, className: "logs-level-error" },
        { title: "Fatal", id: 4, className: "logs-level-fatal" },
    ],
    Sources: [
        { title: "All Sources", id: "" },
        { title: "Morabaa7", id: "Morabaa7", className: "text-cyan-600" },
    ],

    PagesSize: [
        { title: "25", id: 25 },
        { title: "50", id: 50 },
        { title: "75", id: 75 },
        { title: "100", id: 100 },
    ],

    BaseLogSort: [
        { title: "التاريخ", id: "onlineId", className: "text-red-600" },
        { title: "الايقونة", id: "versionId", className: "text-cyan-600" },
    ],
};

const SectionOne = {
    children: [
        {
            key: "filter",
            type: "Button",
            title: "outer filter",
            value: "",
            className: "text-red-400",
            children: [
                { key: "test", type: "String", title: "اختبار", value: "", className: "text-cyan-400" },
                { key: "isActive", type: "Boolean", title: "مفعل", value: true },
                { key: "createdAt", type: "Date", title: "التاريخ", value: "" },
            ],
        },
    ],
};

const SetctionTwo = {
    children: [
        { key: "level", type: "Options", title: "المستوى", options: options.Levels, storageKey: "log-level" },
        { key: "sort", type: "Options", title: "ترتيب", options: options.BaseLogSort, storageKey: "logs-sort", value: "versionId" },
        { key: "limit", type: "Options", title: "عدد الصفوف", options: options.PagesSize, storageKey: "logs-limit" },
        { key: "source", type: "Options", title: "المصدر", options: options.Sources, storageKey: "logs-source", value: "" },
    ],
};

const SetctionThree = {
    children: [
        { key: "businessTitle", type: "String", title: "الاسم بالدنكل", value: "" },
        { key: "dongleId", type: "String", title: "الدنكل", value: "" },
        { key: "content", type: "String", title: "الرسالة", value: "" },
    ],
};

const SectionFour = {
    children: [
        { key: "desc", type: "Boolean", title: "تنازلي", value: true },
        { key: "distinct", type: "Boolean", title: "بدون تكرار", value: false },
        { key: "from", type: "Date", title: "من", value: null },
        { key: "to", type: "Date", title: "الى", value: null },
        { key: "useTime", type: "Boolean", title: "تحديد تاريخ", value: false },
    ],
};

// const querySections = [SectionOne, SetctionTwo, SetctionThree, SectionFour];
const querySections = [SectionOne];

const onResult = (data) => {
    data.data.forEach((item) => {
        item.happenedAt = new Date(item.happenedAt).toLocaleString().split(",");
        item.version -= 70000;
        item.message.numberOfRepetitions = Formatter(item.message.numberOfRepetitions);
        item.expand = item.message.content.length > 320 ? false : true;
    });
    return data;
};

const onError = (e) => {
    // alert(JSON.stringify(e.message));
};
const url = "logs";

export default { url, querySections, onResult, onError };
// const base = "logs";
// const OnReaslt = (data) => {};
// const Query = {
//     companyName: { type: "String", title: "اسم الشركة", value: "" },
// };
