import InputText from "./InputText";
import React, { useState } from "react";
import PopupContainer from "./Dialog";
import ErrorStatusOptionsCard from "./ErrorStatusOptionsCard";

const ErrorControllerPopup = ({ error, close, submit }) => {
    const [status, setStatus] = useState(error.status || 0);
    const [note, setNote] = useState(error.note || "");
    const [solvedAtVersionId, setSolvedAtVersionId] = useState(error.solvedAtVersionId);
    const [animation, setAnimation] = useState("fade-in");
    const _close = () => {
        setAnimation("fade-out");
        setTimeout(() => {
            close(null);
        }, 140);
    };

    const _submit = () => {
        const data = { status, note, solvedAtVersionId };
        if (error.id) {
            data.id = error.id;
            let oldError = error;
            submit(data, oldError);
        } else submit(data);
        _close();
    };

    return (
        <PopupContainer animation={animation} close={_close}>
            <div className="popHolder p-4 md:p-10 scroller">
                <div className="col gap-3">
                    <ErrorStatusOptionsCard setStatus={setStatus} status={status} />
                    <InputText placeholder="تم الحل بالأيقونة" value={solvedAtVersionId} setValue={setSolvedAtVersionId} />
                    <InputText placeholder="الملاحظة" value={note} setValue={setNote} />
                    <p onClick={_submit} className="font-bold cyan-card mt-8 px-3 py-1 cursor-pointer text-white rounded-xl mx-auto" type="submit">
                        ارسال
                    </p>
                </div>
            </div>
        </PopupContainer>
    );
};
export default ErrorControllerPopup;
