import React from "react";
import GetQueryChild from "../QueryKit.js/QueryKit";

const QueryGroupContainer = ({ prop }) => {
    console.log("QueryGroupContainer RD");

    const final = React.useMemo(() => {
        let _final = [];
        prop.children?.map((group) => {
            let _bodies = [];
            group.children.map((child) => {
                child.onChange = () => {
                    prop.onChange(child);
                };
                if (child.children && !child.type) child.type = "Group";
                _bodies.push(GetQueryChild(child));
            });
            _final.push({
                className: group.className || "owl-query-section",
                bodies: _bodies,
            });
        });
        return _final;
    }, []);

    return final.map((group) => (
        <div key={group.bodies[0].key} className={group.className}>
            {group.bodies}
        </div>
    ));
};
export default QueryGroupContainer;
