import React from "react";
import CheckBox from "./CheckBox";
import CopyText from "./TextCopy";

const DongleCard = ({ dongle, onDongleClicked }) => {
    const className = `logs-card text-[16px]  gap-2 col font-bold p-4 ${dongle.isBlocked && "blocked"} ${dongle.isMain && "main"} `;
    return (
        <div key={dongle.id} className={className}>
            <div className="select-none px-3 pb-2 border-b row -mx-1">
                <CopyText info={dongle.id} />
                <CheckBox
                    setValue={() => {
                        onDongleClicked(dongle.id);
                    }}
                    value={dongle.selected}
                    extraClass="mr-auto"
                />
            </div>

            <p className="flexRow items-center select-none dongle-type p-0.5 mt-1 -mx-4">
                <span className="material-icons px-3">computer</span>
                <span>{dongle.machineName}</span>
                {dongle.screenWorkingArea && <span className="mr-3 text-sm">({dongle.screenWorkingArea})</span>}
            </p>

            {/* 
            <p className="text-center">
                <span className="text-gray-500 text-sm"> companies :</span>
                &#160;
                <span className="text-red-400">{dongle.companies?.length}</span>
            </p> */}
            <div className="max-h-[200px] overflow-auto scroller">
                {dongle.companies.map((company, i) => (
                    <a
                        href="/reports/companies"
                        target="_black"
                        className="row items-center hover:bg-gray-200 flex-grow px-2 py-1"
                        key={i}
                        onClick={() => {
                            let compainesQuery = { query: { companyId: company.softwareUUId }, title: company.name || "لايوجد" };
                            localStorage.setItem("companies-query", JSON.stringify(compainesQuery));
                        }}>
                        <p className="">
                            <span> {i + 1} - </span>
                            <span className="px-3"> {company.name || "لايوجد "}</span>
                            {company.softwareUUId === dongle.companyId && <span className=" text-sm red-text">(الشركة المعتمدة)</span>}
                        </p>
                    </a>
                ))}
            </div>
            <a
                href="/reports/companies"
                target="_black"
                className="mr-2 ml-auto bg-white"
                onClick={() => {
                    let compainesQuery = { query: { dongleId: dongle.id }, title: `Dongle Id : ${dongle.id || "لايوجد"}`, defualtCompany: dongle.companyId };
                    localStorage.setItem("companies-query", JSON.stringify(compainesQuery));
                }}>
                <p className="text-sm px-3 shadow-1 rounded">
                    <span className="text-red-400">{"تفاصيل"} </span>
                </p>
            </a>
        </div>
    );
};

export default DongleCard;
