import React from "react";
import JsonToView from "./JsonToView";

const PageStateTester = ({ set, state }) => {
    const LoadingStatus = () => {
        set("pageState", state);
        setTimeout(() => {
            set("pageState", "none");
        }, 3000);
    };
    return (
        <p className="shadow-1 rounded-2xl px-5 p-1" onClick={LoadingStatus}>
            {state}
        </p>
    );
};

const DefaultItemBuilder = ({ item, set, setItem }) => {
    return (
        <div className="item-builder">
            <div className="row-center justify-around">
                <PageStateTester set={set} state="loading" />
                <PageStateTester set={set} state="itemsLoading" />
                <PageStateTester set={set} state="progressing" />
                <PageStateTester set={set} state="searching" />
            </div>
            <JsonToView item={item} />
        </div>
    );

    // console.log("ItemRD");

    const T = `<div> <span className="text-gray-700">{"["}</span></div>`;

    // const jsonRef = React.useRef(null);
    // React.useEffect(() => {
    //     setTimeout(() => {
    //         console.log("jsonRef", jsonRef.current);
    //     }, 1000);
    // }, []);
    const ref = React.useRef(null);
    const getElementIndex = (e) => {
        let indexes = [];
        e.currentTarget.childNodes.forEach((element, i) => {
            // console.log(element.childNodes);
            if (element.id === "isMain") {
                indexes.push(i);
                console.log({ i });
                element.childNodes.forEach((_element, i2) => {
                    if (_element.id === "value") {
                        _element.textContent = `${item.isMain}`;
                        console.log({ i2 });
                    }
                });
            }
        });
    };

    return (
        <div
            key={item.id}
            onClick={(e) => {
                // e.currentTarget.innerHTML = T.outerHTML;
                console.log(e.target.id);
                if (e.target.id) {
                    alert(e.target.id);
                    return;
                    console.log(e.target.childNodes);
                    e.target.childNodes[2].textContent = "lol";
                } else if (e.target.parentId) {
                    alert(e.target.parentId);
                }
                return;
                item.isMain++;
                console.log("item.isMain", item.isMain);
                // e.currentTarget.firstElementChild.setAttribute("item", item);
                // console.log(e.currentTarget.firstElementChild.);
                // e.currentTarget.firstChild = `<JsonToView item={${item}} /> `;
                // console.log(e.currentTarget.firstChild.childNodes.forEach(element => {
                // e.currentTarget.firstChild.childNodes[1].childNodes[2].textContent = `${item.isMain}`;
                e.currentTarget.childNodes[1].childNodes[2].textContent = `${item.isMain}`;
                return;
                e.currentTarget.childNodes.forEach((element, i) => {
                    // console.log(element.childNodes);
                    if (element.id === "isMain") {
                        console.log({ i });
                        element.childNodes.forEach((_element, i2) => {
                            if (_element.id === "value") {
                                _element.textContent = `${item.isMain}`;
                                console.log({ i2 });
                            }
                        });
                    }
                });

                // e.currentTarget.firstElementChild.innerHTML = `<JsonToView item={${JSON.stringify(item)}} /> `;
                //<p src='https://png.pngtree.com/element_our/png/20180928/beautiful-hologram-water-color-frame-png_119551.jpg' onerror='alert(1)'>${item.s}</p>`;

                //     e.currentTarget.firstElementChild.innerHTML = `<div onClick={onClick} key={key} className={'obj flex-grow '}>
                //      <span className={'font-bold rounded} style={{ fontSize: 12, letterSpacing: 1, paddingTop: 4 }}>
                //          {key}
                //      </span>
                //      <span className="text-gray-400 px-2">{":"}</span>
                //      {UiKit[type]({ key: {_i}, item: {value} })}
                //  </div>
                //  `;
            }}
            className="item-builder">
            <JsonToView item={item} />
            {/* <p>lol</p> */}
            {/* {children} */}
        </div>
    );
};

export default DefaultItemBuilder;

const EmptyCard = () => {
    return <div className="bg-gray-200 p-4 font-bold text-center rounded-lg shadow-1 text-red-500">{"No card to display"}</div>;
};

const Methods = {
    isMain: (item) => {
        item.isMain = !item.isMain;
    },
};

const sample = {
    id: "943-34234kf-f32f-23f32f-c8",
    name: "Jhon Doe",
    descriptionT: "somthing going on here ok then this is a description so that is not a good idea",
    amBoolean: true,
    objectColction: [{ id: "KKKK" }, { name: "Obdestest" }, { description: "obdes" }],
    colction: ["one ", "test", "four", "four", "four", "owls"],
    object: {
        id: "K-sdf-KK-sdfK",
        name: "Jhon Doe",
        description: "obdes",
        description: "this is a description",
        idK: "K-sdf-KK-sdfK",
        nameK: "Obdes test by Jhon Doe",
    },
};
const sample0 = {
    id: "943-34234kf-f32f-23f32f-c8",
    amBoolean: true,
    colction: ["one ", "test", "four", "four", "four", "owls"],
    object: {
        ar: [
            {
                colction: ["one ", "test", "four", "four", "four", "owls"],
                colctionObjsTwo: {
                    name: "Jhon Doe",
                    colction: [{ id: "KKKK" }, { name: "Obdestest" }, { description: "obdes" }],
                },
                id: "K-sdf-KK-sdfK",
            },
        ],
        colction: ["one ", "test", "four", "four", "four", "owls"],
        colctionObjs: {
            name: "Jhon Doe",
            colction: ["one ", "test", "four", "four", "four", "owls"],
        },
        id: "K-sdf-KK-sdfK",
    },
};
