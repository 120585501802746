const Date = ({ prop }) => {
    console.log("Date RD");
    const onSelect = ({ target }) => {
        prop.value = target.value;
        prop.onChange();
    };
    return (
        <div key={prop.key} className="owl-label-conatiner">
            <input type="date" className="owl-date" defaultValue={prop.value} onChange={onSelect} />
            <p className="owl-label">{prop.title} </p>
        </div>
    );
};
export default Date;
