import React from "react";

const Loading = ({ data }) => {
    return (
        <div className="h-[60vh] flex">
            <div className="lds-ripple">
                <div></div>
                <div></div>
            </div>
        </div>
    );
};

export default Loading;
