import React from "react";

const Button = ({ onClick, title, className }) => {
    return (
        <p onClick={onClick} className={"owl-button " + className}>
            {title}
        </p>
    );
};

export default Button;
