import React from "react";
import { Link, Route, Routes } from "react-router-dom";
import BaseLogsView from "./View/BaseLogsView";
import CompainesView from "./View/CompainesView";
import CompaniesDetails from "./View/CompaniesDetails";
import DonglesView from "./View/DonglesView";
import ErrorsView from "./View/ErrorsView";
import "./_testing/css/owl.css";
import "./logs.css";
import "./loader.css";
import "./generals.css";
import "./_testing/css/others.css";
import MainHeader from "./MainHeader";
import SetupView from "./View/SetupView";
import TEST from "./View/TEST";

const TestRouts = () => {
    console.log("TestRouts RD");
    return (
        <>
            <MainHeader />
            <Routes>
                <Route path="/" element={<SetupView />} />
                <Route path="/test" element={<TEST />} />
                <Route path="/base" element={<BaseLogsView />} />
                <Route path="/errors" element={<ErrorsView />} />
                <Route path="/companies" element={<CompainesView />} />
                <Route path="/Oldcompanies" element={<CompaniesDetails />} />
                <Route path="/dongles" element={<DonglesView />} />
            </Routes>
        </>
    );
};

export default TestRouts;
