import React from "react";
import ErrorControllerPopup from "../Components/ErrorControllerPopup";
import JsonToView from "../Defaults/JsonToView";
import MainLoader from "../_testing/Components/MainLoader";
import MessageCard from "../_testing/Components/MessageCard";
import LoaderContainer from "../_testing/Components/_testLoaderContainer";
import OwlConatiner from "../_testing/OwlConatiner";
import Errors from "../_testing/ViewsData/Errors";

const Child = ({ data, set }) => {
    return (
        <div>
            {/* <JsonToView item={data}></JsonToView> */}
            {data.itemToUpdate && <ErrorControllerPopup error={data.itemToUpdate} close={() => set({ itemToUpdate: false })} />}
        </div>
    );
};

const ErrorsView = () => {
    console.log("ErrorsView RD");
    return (
        <>
            {/* {LoaderContainer(Owl, MainLoader)} */}
            <OwlConatiner
                //
                url={Errors.url}
                onResult={Errors.onResult}
                // itemBuilder={MessageCard}
                queries={Errors.queries}
                onError={Errors.onError}
                child={Child}
                // itemsConatinerClass="owl-grid"
                // queryContainerClass="owl-query-conainer"
                // querySectionClass="owl-query-section"
            />
        </>
    );
};

export default ErrorsView;
