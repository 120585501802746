import React from "react";

const PopupContainer = ({ children, close, animation }) => {
    return (
        <div
            id="13"
            onClick={({ target }) => {
                if (target.id === "13") close();
            }}
            className={`popup-Message-container p-3 md:p-10 ${animation}`}>
            {children}
        </div>
    );
};

export default PopupContainer;
