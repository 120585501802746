import React from "react";

const CheckBox = ({ setValue, value, title, storge, extraClass }) => {
    const className = `checked-box rounded-[8px] cursor-pointer ${value ? "checked" : ""} ${extraClass}`;

    const onCheck = () => {
        let newVal = !value;
        storge && localStorage.setItem(storge, newVal ? "1" : "0");
        setValue(newVal);
    };

    return (
        <svg className={className} onClick={onCheck} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.7071 7.29289C19.0976 7.68342 19.0976 8.31658 18.7071 8.70711L11.4142 16C10.6332 16.781 9.36683 16.781 8.58579 16L5.29289 12.7071C4.90237 12.3166 4.90237 11.6834 5.29289 11.2929C5.68342 10.9024 6.31658 10.9024 6.70711 11.2929L10 14.5858L17.2929 7.29289C17.6834 6.90237 18.3166 6.90237 18.7071 7.29289Z" />
        </svg>
    );
};

export default CheckBox;
