import React from "react";
import Options from "../Utils/Globals";

const ErrorStatusOptionsCard = ({ setStatus, status }) =>
    Options.ErrorStateus?.map((item, i) => (
        <div className={`relative selectble${item.id === status ? "-active" : ""}`} onClick={() => setStatus(item.id)}>
            <p className="dynimc-bg absolute left-0 p-3 rounded-[3px]" style={{ backgroundColor: Options.ErrorColors[i] }} />
            <p className="relative z-20 title">{item.title}</p>
        </div>
    ));

export default ErrorStatusOptionsCard;
