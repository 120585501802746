import React from "react";

const getClass = (prop) => "owl-options " + (prop.className || "");

const Options = ({ prop }) => {
    // const [, render] = React.useState(prop);
    console.log("Options RD");
    const _class = getClass(prop);

    return (
        <select
            key={prop.key}
            defaultValue={prop.value}
            className={_class}
            onChange={(e) => {
                let value = e.target.value;
                prop.value = value;
                prop.className = prop.options.find((o) => o.id == value)?.className;
                e.currentTarget.className = getClass(prop);
                if (prop.storageKey) localStorage.setItem(prop.storageKey, value);
                prop.onChange();
            }}>
            {prop.options.map((item) => (
                <option value={item.id} key={item.id}>
                    {item.title}
                </option>
            ))}
        </select>
    );
};

export default Options;
