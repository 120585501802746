import React from "react";
import { HubCore, HubLogsAPI, SotreAPI } from "../Api";
import LogsCard from "../Components/LogsCard";
import CountHolder from "../_testing/Components/CountHolder";
import MainLoader from "../_testing/Components/MainLoader";
import LoaderContainer from "../_testing/Components/_testLoaderContainer";
import OwlConatiner from "../_testing/OwlConatiner";
import Logs from "../_testing/ViewsData/Logs";

const Options = [
    { title: "التاريخ", id: "createdAt" },
    { title: "الاسم", id: "name" },
    { title: "العدد", id: "count" },
];
const Options2 = [
    { title: "التاريخ", id: "createdAt" },
    { title: "الاسم", id: "name" },
    { title: "العدد", id: "count" },
];

const testSection = {
    children: [
        //
        { key: "content", title: "المحتوى", type: "String", value: "" },
        { key: "companyName", type: "String", title: "اختبار", value: "" },
        { key: "createdAt", type: "Date", title: "التاريخ", value: "" },
        { key: "sort", type: "Options", options: Options2, storageKey: "logs-sort" },
        { key: "isActive", type: "Boolean", title: "مفعل", value: true, storageKey: "logs-isActive" },
        { key: "sorter", type: "ButtonsOptions", options: Options, storageKey: "logs-sorters", value: "count" },
        {
            key: "filter",
            type: "Button",
            title: "outer filter",
            children: [
                { key: "test", type: "String", title: "اختبار", value: "" },
                { key: "createdAt", type: "Date", title: "التاريخ", value: "" },
                { key: "sort", type: "Options", options: Options, storageKey: "logs-sorts" },
                { key: "sortOptions", type: "ButtonsOptions", options: Options, storageKey: "logs-sortr" },
                { key: "isActive", type: "Boolean", title: "مفعل", value: true, storageKey: "is-active" },
                {
                    key: "filter",
                    type: "Button",
                    title: "inner filter",
                    children: [
                        { key: "test", type: "String", title: "اختبار", value: "" },
                        { key: "createdAt", type: "Date", title: "التاريخ", value: "" },
                        { key: "isActive", type: "Boolean", title: "مفعل", value: true },
                        { key: "sort", type: "ButtonsOptions", options: Options, storageKey: "logs-sort" },
                    ],
                },
            ],
        },
    ],
};

const querySections = [testSection];

const Header = ({ data }) => (
    <div className="" style={{ marginBottom: 10 }}>
        <CountHolder data={data} />
    </div>
);
const ErrorBuilder = ({ data }) => <div className="bg-white ">{JSON.stringify(data.error)}</div>;

const ItemBuilder = ({ item }) => (
    <div className="bg-white shadow-1 p-4 rounded-xl font-bold">
        <p className="font-bold">{"Test ItemBuilder "}</p>
        <p>onlineId:{item.onlineId}</p>
    </div>
);

const Loader = () => <div className="bg-white ">{"Loading..."}</div>;
const NoData = () => <div className="bg-white ">{"No Data"}</div>;

// const onResult = (data) => {
//     console.log(data);
//     let newItems = [];
//     data.map((item) => newItems.push({ images: item.images, ...item }));
//     return newItems;
// };

const BaseLogsView = () => {
    console.log("BaseLogsView RD");
    return (
        <>
            <OwlConatiner
                url={"logs"}
                queries={querySections}
                // onResult={onResult}
                // onError={Logs.onError}
                // loader={Loader}
                // noData={NoData}
                child={Header}
                // errorBuilder={ErrorBuilder}
                // itemBuilder={ItemBuilder}
                // itemsConatinerClass="owl-grid"
                // querySectionClass="owl-query-section p-2 gap-2 m-2 shadow-1"
                // queryContainerClass="owl-query-conainer"
                api={HubLogsAPI}
            />
        </>
    );

    // return <OwlConatiner itemBuilder={LogsCard} child={logHeader} url={Logs.url} queries={Logs.queries} onResult={Logs.onResult} onError={Logs.onError}
};

export default BaseLogsView;
